import type { ComponentPropsWithRef } from 'react'
import { cn } from '~/utils/utils'

export function LoungePairPlusLogo(
  props: ComponentPropsWithRef<'svg'> & {
    alt?: string
    dark?: boolean
    excludePlus?: boolean
  },
) {
  return (
    <svg
      id="logo"
      className={cn(
        props.dark ? 'text-white' : 'text-midnight-900',
        props.className,
      )}
      viewBox={props.excludePlus ? '0 0 240 48' : '0 0 260 48'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.alt ?? 'LoungePair+ Logo'}</title>
      <path
        d="M231.137 16.7615C232.251 16.7615 233.063 16.942 233.755 17.2729L232.672 21.304C231.89 20.8829 231.047 20.6723 229.994 20.6723C228.069 20.6723 226.775 22.2366 226.775 25.0644V33.8488H222.383V17.0021H226.745V20.8227C227.497 18.0551 228.761 16.7615 231.137 16.7615Z"
        fill="currentColor"
      />
      <path
        d="M213.149 11.7976C213.149 10.143 214.111 8.99988 215.916 8.99988C217.631 8.99988 218.654 10.143 218.654 11.7976C218.654 13.392 217.631 14.5051 215.916 14.5051C214.111 14.5051 213.149 13.392 213.149 11.7976ZM213.6 17.002H218.022V33.8486H213.6V17.002Z"
        fill="currentColor"
      />
      <path
        d="M192.659 29.9079C192.659 27.9826 193.501 26.8695 194.734 26.0572C195.938 25.2751 197.562 24.8238 200.841 24.162C203.639 23.5904 204.15 23.0489 204.15 22.0562C204.15 20.9732 203.308 20.1008 201.142 20.1008C198.946 20.1008 197.622 21.3041 197.021 23.2294L193.05 22.0862C194.464 18.386 197.622 16.6713 201.353 16.6713C205.564 16.6713 208.332 18.7169 208.332 23.0188V28.7948C208.332 30.3591 208.783 30.8104 209.896 30.8104C210.348 30.8104 210.739 30.6901 211.07 30.5697V33.3975C210.468 33.7886 209.445 34.1496 208.182 34.1496C205.474 34.1496 204.331 32.9463 204.181 30.3291C203.128 32.7658 200.841 34.1496 197.983 34.1496C194.734 34.1496 192.659 32.5853 192.659 29.9079ZM204.271 26.3581V25.3352C203.85 25.8166 202.947 26.1475 201.172 26.5386C198.224 27.1703 197.081 27.6817 197.081 29.1257C197.081 30.3591 197.983 30.9307 199.698 30.9307C202.737 30.9307 204.211 28.1931 204.271 26.3581Z"
        fill="currentColor"
      />
      <path
        d="M181.95 27.411H176.806V33.8488H172.384V11.8881H181.619C187.606 11.8881 190.704 14.8964 190.704 19.469C190.704 24.4027 187.155 27.411 181.95 27.411ZM181.649 15.9493H176.806V24.0116H181.499C184.628 24.0116 186.403 22.7481 186.403 19.9805C186.403 17.0624 184.567 15.9493 181.649 15.9493Z"
        fill="currentColor"
      />
      <path
        d="M152.031 25.5157C152.031 20.7626 155.34 16.6713 160.725 16.6713C166.441 16.6713 168.998 20.6723 168.998 25.0344V26.6589H156.273C156.634 29.0956 158.198 30.5096 160.815 30.5096C162.77 30.5096 163.853 29.7876 164.846 28.0427L168.546 29.9981C166.982 32.5251 164.575 34.1496 160.875 34.1496C155.039 34.1496 152.031 30.5096 152.031 25.5157ZM160.725 20.2813C158.288 20.2813 156.754 21.6049 156.333 23.9514H164.696C164.575 21.8757 163.462 20.2813 160.725 20.2813Z"
        fill="currentColor"
      />
      <path
        d="M131.646 37.2783L135.677 35.0521C136.64 36.6465 137.693 37.4888 140.009 37.4888C142.686 37.4888 144.221 35.8643 144.221 32.3446V30.4494C143.168 32.7056 141.333 33.9992 138.595 33.9992C134.594 33.9992 131.495 31.1714 131.495 25.5157C131.495 19.6796 135.166 16.8217 138.896 16.8217C141.543 16.8217 143.258 17.8746 144.251 20.2211V17.0022H148.613V31.9234C148.613 38.2409 145.274 41.2793 139.678 41.2793C135.827 41.2793 133.15 39.8654 131.646 37.2783ZM144.522 25.4255C144.522 22.3871 143.318 20.3113 140.25 20.3113C137.271 20.3113 135.978 22.4472 135.978 25.4255C135.978 28.4338 137.121 30.4494 140.25 30.4494C143.318 30.4494 144.522 28.4037 144.522 25.4255Z"
        fill="currentColor"
      />
      <path
        d="M111.98 33.8488V17.0022H116.342V20.6723C117.485 17.9648 119.501 16.6713 122.088 16.6713C125.788 16.6713 128.165 19.3186 128.165 23.3497V33.8488H123.773V24.5832C123.773 21.8757 122.72 20.3414 120.133 20.3414C117.666 20.3414 116.372 22.2367 116.372 25.3352V33.8488H111.98Z"
        fill="currentColor"
      />
      <path
        d="M107.647 17.0022V33.8488H103.285V30.0583C102.142 32.7959 100.156 34.1797 97.7195 34.1797C93.8087 34.1797 91.763 31.5324 91.763 27.5012V17.0022H96.1552V26.2678C96.1552 28.9753 97.0878 30.5096 99.6148 30.5096C101.991 30.5096 103.255 28.3737 103.255 25.3653V17.0022H107.647Z"
        fill="currentColor"
      />
      <path
        d="M79.7894 34.1496C74.1639 34.1496 71.0352 30.5096 71.0352 25.4255C71.0352 20.4016 74.3143 16.6713 79.7894 16.6713C85.415 16.6713 88.5437 20.4918 88.5437 25.4255C88.5437 30.3591 85.2044 34.1496 79.7894 34.1496ZM79.7894 30.4494C82.8579 30.4494 84.0613 28.4037 84.0613 25.4255C84.0613 22.3871 82.8579 20.3113 79.7894 20.3113C76.8112 20.3113 75.5176 22.4472 75.5176 25.4255C75.5176 28.4338 76.6608 30.4494 79.7894 30.4494Z"
        fill="currentColor"
      />
      <path
        d="M53.7547 33.8488V11.8881H58.177V29.7876H69.1273V33.8488H53.7547Z"
        fill="currentColor"
      />
      {props.excludePlus ? null : (
        <path
          d="M237.188 25.7445V21.0077H244.652V13.4H249.723V21.0077H257.188V25.7445H249.723V33.4H244.652V25.7445H237.188Z"
          fill="#316BFF"
        />
      )}

      <rect y="3" width="42" height="42" rx="5.6" fill="#316BFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0579 14.375C10.0579 11.1855 12.6327 8.59998 15.809 8.59998C18.9852 8.59998 21.56 11.1855 21.56 14.375V23.23C21.56 24.2931 20.7017 25.155 19.643 25.155C18.5842 25.155 17.726 24.2931 17.726 23.23V13.605C17.726 12.5418 16.8677 11.68 15.809 11.68C14.7502 11.68 13.8919 12.5418 13.8919 13.605C13.892 16.8295 13.8919 20.0541 13.8919 23.2787C13.8919 25.821 15.8641 27.85 18.2052 27.85C21.2405 27.85 24.2758 27.85 27.311 27.85C30.4872 27.85 33.0621 30.4355 33.0621 33.625C33.0621 36.8144 30.4872 39.4 27.311 39.4C24.2758 39.3998 21.0488 39.4 18.0135 39.4C14.1356 39.4 10.9422 36.5313 10.0898 32.9016C9.95772 32.339 10.6903 32.0729 11.0929 32.486C12.8928 34.3332 15.4786 35.546 18.0039 35.55C21.298 35.554 24.7838 35.5498 28.0778 35.55C29.1366 35.55 29.9949 34.6881 29.9949 33.625C29.9949 32.5618 29.1366 31.7 28.0778 31.7L18.1094 31.6994C13.6139 31.6462 10.0579 27.855 10.0579 23.2787C10.0579 20.3108 10.0579 17.3429 10.0579 14.375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1094 31.6994C13.614 31.6462 10.0579 27.8551 10.0579 23.2787V14.375C10.0579 11.1856 12.6328 8.60001 15.809 8.60001C18.9852 8.60001 21.56 11.1856 21.56 14.375V23.23C21.56 24.2932 20.7017 25.155 19.643 25.155C18.5843 25.155 17.726 24.2932 17.726 23.23V13.605C17.726 12.5419 16.8677 11.68 15.809 11.68C14.7502 11.68 13.892 12.5419 13.892 13.605V23.2787C13.892 25.821 15.8641 27.85 18.2052 27.85L27.311 27.85C30.4872 27.85 33.0621 30.4356 33.0621 33.625C33.0621 36.8145 30.4872 39.4 27.311 39.4C25.2805 39.3999 23.1642 39.3999 21.0769 39.4C20.044 39.4 19.0183 39.4 18.0135 39.4C14.1356 39.4 10.9422 36.5313 10.0899 32.9017C9.95772 32.339 10.6903 32.0729 11.0929 32.4861C12.8928 34.3332 15.4786 35.5461 18.0039 35.55C20.1574 35.5526 22.3928 35.5517 24.6031 35.5509C25.7737 35.5504 26.9373 35.5499 28.0778 35.55C29.1366 35.55 29.9949 34.6882 29.9949 33.625C29.9949 32.5619 29.1366 31.7 28.0778 31.7L18.1094 31.6994ZM18.0047 35.0388C20.1578 35.0414 22.3924 35.0405 24.6024 35.0397C25.7731 35.0392 26.9368 35.0387 28.0778 35.0388C28.8522 35.0388 29.4837 34.4078 29.4837 33.625C29.4837 32.8422 28.8522 32.2112 28.0778 32.2112L18.1033 32.2106C13.3095 32.1539 9.54672 28.1162 9.54672 23.2787V14.375C9.54672 10.9052 12.3484 8.08881 15.809 8.08881C19.2695 8.08881 22.0712 10.9052 22.0712 14.375V23.23C22.0712 24.5735 20.9861 25.6662 19.643 25.6662C18.2999 25.6662 17.2148 24.5735 17.2148 23.23V13.605C17.2148 12.8222 16.5834 12.1912 15.809 12.1912C15.0346 12.1912 14.4032 12.8222 14.4032 13.605V23.2787C14.4032 25.5625 16.1697 27.3388 18.2052 27.3388L27.311 27.3388C30.7716 27.3388 33.5733 30.1552 33.5733 33.625C33.5733 37.0948 30.7716 39.9112 27.311 39.9112C25.2805 39.9111 23.1642 39.9111 21.0769 39.9112C20.0441 39.9112 19.0183 39.9112 18.0135 39.9112C13.8726 39.9112 10.4927 36.8532 9.59219 33.0185C9.53143 32.7598 9.56579 32.5033 9.69157 32.2855C9.81372 32.0741 10.0034 31.9309 10.204 31.8521C10.5994 31.6968 11.1047 31.7656 11.4591 32.1293C13.1752 33.8905 15.6337 35.0351 18.0047 35.0388Z"
        fill="white"
      />
    </svg>
  )
}
